<template>
    <div>
<!--    服务说明    -->
        <div class="title">
            服务说明
        </div>
<!--    产品说明    -->
        <div class="title_2">
        产品说明
        </div>
        <div class="content">
            自助电动车扫码充电系统，通过手机支付宝扫描设备上的二维码，选择对应的空闲端口（没有电动车充电的插座）和充电套餐（要充电的时间和金额），进行自助付款充电。
        </div>
<!--  收费标准 -->
        <div class="title_2">
            收费标准
        </div>
        <div class="content">
            1元可以充电240分钟;<br>2元可以充电480分钟;<br>3元可以充电720分钟;<br>4元可以充电960分钟;<br>到充电时间后设备自动停止充电。
        </div>
            <div :style="{'text-align':'center','vertical-align': 'center'}">
                <van-button :class="'btn_save'"  plain hairline type="primary" @click="save">确定</van-button>
            </div>
    </div>
</template>

<script>

    import {CellGroup,Card, Field, Button, Sticky, Tag, Toast} from 'vant';
    import {isEmpty} from "../../utils/common";

    export default {
        name: "about",
        components: {
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
            [Sticky.name]: Sticky,
            [Field.name]: Field,
            [Button.name]: Button,
            [Card.name]: Card,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
            };
        }

        , mounted() {

        }
        , methods: {
            save() {
                //跳转到index
                this.$router.push({name: 'index'});
            }
        }
    }
</script>

<style scoped>
    .btn_save {
        width: 60%;
        display: inline-block;
        height: auto;
        /*margin: 5px;*/
        /*height: 0.66667rem;*/
        /*line-height: 0.66667rem;*/
        text-align: center;
        margin-bottom: 0.6rem;
        margin-top: 0.6rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
    .title{
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        font-size: x-large;
        text-align: center;
    }
    .title_2{
        padding: 0.2rem;
        font-size: large;
        color: #a3cc8b;
    }
    .content{
        font-size: medium;
        padding: 0.2rem;
    }
</style>
